@charset 'UTF-8';



.twitter {
    margin: 12px;
    padding: 15px;
    border: 1px solid #cfd9de;
    border-radius: 12px;

    &__title {
        padding-bottom: 5px;
        font-size: 85%;
    }

    &__button {
        display: block;
        padding: 10px;
        background-color: #000;
        color: #fff !important;
        font-size: 75%;
    }
}